export default {
    data(){
        return {
            options: [],
            loading: false,
            userInfo: this.$store.getters.userInfo,
            formData: {},
            areaText: '',
        }
    },
    mounted: function(){
        if(!this.userInfo) return
        this.formData = {
            clientname: this.userInfo.clientname,
            clientid: this.userInfo.clientid,
            clientbirthday: this.userInfo.clientbirthday,
            clientsex: this.userInfo.clientsex,
            clientidcard: this.userInfo.clientidcard,
            clientmobile: this.userInfo.clientmobile,
            clientemail: this.userInfo.clientemail,
            clientaddress: this.userInfo.clientaddress,
            arealevel1id: this.userInfo.arealevel1id,
            arealevel2id: this.userInfo.arealevel2id,
        }

        this.$store.dispatch('getAreaList', {'langcode': 'B5'})
        .then((rs) => {
            this.options = (rs || []).map((d)=>{
                return {
                    ...d,
                    isLeaf: false
                }
            })
            if(this.formData.arealevel1id) {
                var option = this.options.find((d)=>d.codeid == this.formData.arealevel1id)
                this.$store.dispatch('getAreaList', {'langcode': 'B5', 'parentId': this.formData.arealevel1id})
                .then((rs) => {
                    var children = rs || []
                    var o = children.find((d)=>d.codeid==this.formData.arealevel2id)
                    this.areaText = option.codedesc + '/'+ o.codedesc
                    option.children = children
                    this.options = [...this.options]
                })
            }
        })
    },
    methods: {
        getAreaData: function(langcode, areaid, callback){
            this.$store.dispatch('getAreaList', {'langcode': langcode, 'parentId': areaid})
            .then((rs) => {
                callback && callback(rs, null)
            }).catch((rs) => {
                callback && callback(null, rs)
            })
        },
        changeFormData: function(key, value) {
            this.formData[key] = value
        },
        updateUserInfo(callback){
            if(this.loading) return
            if(!this.formData.clientbirthday) {
                this._toast.warning(this, '請選擇出生日期')
                return
            }
            if(!this.formData.clientsex) {
                this._toast.warning(this, '請選擇性別')
                return
            }
            if(!this.formData.clientemail) {
                this._toast.warning(this, '請輸入郵箱')
                return
            }
            if(!this.formData.arealevel1id || !this.formData.arealevel2id) {
                this._toast.warning(this, '請選擇地址')
                return
            }
            if(!this.formData.clientaddress) {
                this._toast.warning(this, '請輸入具體地址')
                return
            }
            this.loading = true
            this.$store.dispatch('updateUserInfo', this.formData)
            .then((rs) => {
                this.loading = false
                callback && callback(rs, null)
            }).catch((rs) => {
                this.loading = false
                callback && callback(null, rs)
            })
        }
    },
}