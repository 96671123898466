<template>
	<div id="user-info">
		<div class="form-item">
			<div class="hint-w">
				<label class="hint" for="user-name">姓名:</label>
			</div>
			<input class="item-v readonly" type="text" id="user-name" placeholder="請輸入姓名" readonly :value="formData.clientname">
		</div>
		<div class="form-item">
			<span class="icon"></span>
			<div class="hint-w">
				<label class="hint require" for="birthday">出生日期:</label>
			</div>
			<div class="birthday-wrapper">
				<input type="text" class="birthday" placeholder="請選擇您的出生日期" @click="pickDate" v-model="formData.clientbirthday">
				<a-date-picker placeholder="請選擇您的出生日期" @change="onBirthdayChanged" :value="formData.clientbirthday ? moment(formData.clientbirthday, 'YYYY-MM-DD') : null " class="date-picker-box" ref="myBtn" format='YYYY-MM-DD'/>
			</div>
		</div>
		<div class="form-item">
			<div class="hint-w">
				<span class="hint require">性別:</span>
			</div>
			<span :class="{'checkbox': true, 'checked': formData.clientsex==1}" @click="changeFormData('clientsex', 1)">男</span>
			<span :class="{'checkbox': true, 'checked': formData.clientsex==2}" @click="changeFormData('clientsex', 2)">女</span>
		</div>
		<div class="form-item">
			<div class="hint-w">
				<label class="hint" for="idcard">證件號碼:</label>
			</div>
			<input class="item-v readonly" type="text" id="idcard" placeholder="請輸入證件號碼" readonly :value="formData.clientidcard">
		</div>
		<div class="form-item">
			<div class="hint-w">
				<label class="hint" for="phone">聯繫電話:</label>
			</div>
			<input class="item-v readonly" type="tel" id="phone" placeholder="請輸入聯繫電話號碼" readonly :value="formData.clientmobile">
		</div>
		<div class="form-item">
			<div class="hint-w">
				<label class="hint require" for="email">郵箱:</label>
			</div>
			<input class="item-v" type="email" id="email" placeholder="請輸入郵箱" v-model="formData.clientemail">
		</div>
		<div class="form-item">
			<div class="hint-w">
				<label class="hint require">地址:</label>
			</div>
			<div class="item-v readonly" style="cursor: pointer;position: absolute;left: 93px;" @click="chooseArea"><div class="lds-ring1" v-if="areaLoading"><div></div><div></div><div></div><div></div></div>选择地区</div>
			<a-cascader
				class="item-address"
				v-if="options.length"
				:options="options"
				:fieldNames="{ label: 'codedesc', value: 'codeid', children: 'children' }"
				:load-data="loadData"
				placeholder="选择地区"
				change-on-select
				:loading="true"
				:default-value="[formData.arealevel1id, formData.arealevel2id]"
				@change="onChange"
			/>
		</div>
		<div class="form-item" v-if="formData.arealevel1id && formData.arealevel2id">
			<span class="hint-w"></span>
			<input type="text" class="item-v" placeholder="請輸入具體地址" v-model="formData.clientaddress">
		</div>
		<div class="btn-save" @click="submit"><div class="lds-ring2" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在提交...' : '確認保存'}}</div>
	</div>
</template>

<script>
	import user_info from '@/views/mixins/user_info'
	import moment from 'moment'
	export default {
		mixins: [
			user_info,
		],
		data(){
			return {
				areaLoading: false,
			}
		},
		methods: {
			moment,
			chooseArea: function(){
				if(this.options.length) return
				this.areaLoading = true
				this.getAreaData('B5', undefined, (rs, error)=>{
					this.areaLoading = false
					this.options = (rs || []).map((d)=>{
						return {
							...d,
							isLeaf: false
						}
					})
				})
			},
			changeFormData: function(key, value) {
				this.formData[key] = value
				this.formData = {...this.formData}
			},
            onBirthdayChanged: function(date, dateString) {
				this.changeFormData('clientbirthday', dateString)
            },
            pickDate: function(){
                const elem = this.$refs.myBtn.$el.children[0].children[0]
                elem.click()
			},
			onChange(value) {
				if(value.length == 2) {
					this.changeFormData('arealevel1id', value[0])
					this.changeFormData('arealevel2id', value[1])
				}
			},
			loadData(selectedOptions) {
				const targetOption = selectedOptions[selectedOptions.length - 1]
				targetOption.loading = true
				this.getAreaData('B5', targetOption.codeid, (rs, error)=>{
					targetOption.loading = false
					targetOption.children = rs || []
					this.options = [...this.options]
				})
			},
			submit: function(){
				this.updateUserInfo((rs, error)=>{
					if(error) {
						this._toast.warning(this, error)
					} else {
						this._toast.success(this, '用户信息修改成功')
					}
				})
			}
		},
	};
</script>

<style lang="scss">
	.ant-calendar-picker {
		width: 454px;
	}
	
	.ant-calendar-picker-icon {
		display: none;
	}

	#user-info {
		display: flex;
		flex-direction: column;
		padding-left: 15px;
	}
	
	.form-item {
		display: flex;
		flex-direction: row;
		margin-bottom: 20px;
		position: relative;
	}

	.form-item:nth-child(1) {
		margin-top: 10px;
	}

	.form-item > .hint-w {
		display: inline-block;
		width: 77px;
		height: 36px;
		line-height: 36px;
		text-align: right;
		font-weight: 400;
		color: #636363;
		font-size: 16px;
		margin-right: 16px;
	}

	.form-item .ant-input.ant-cascader-input {
		height: 36px;
	}

	.form-item > .hint-w > .hint.require {
		position: relative;
	}

	.form-item > .hint-w > .hint.require::before {
		content: '*';
		position: absolute;
		left: -9px;
		top: -9px;
		color: #ee1b23;
		font-weight: bold;
		font-size: 16px;
	}

	.form-item .item-v,
	.form-item > input {
		height: 36px;
		line-height: 36px;
		border-radius: 4px;
		border: 1px solid #D6E7EB;
		box-sizing: border-box;
		font-weight: bold;
		font-size: 16px;
		color: #231F20;
		width: 454px;
		background-color: transparent;
		padding-left: 16px;
	}

	.form-item .ant-cascader-picker-label {
		font-weight: bold;
		font-size: 16px;
		color: #231F20;
	}

	.form-item .item-v.readonly {
		font-size: 16px;
		font-weight: 400;
		color: #969696;
	}

	.form-item .item-address {
		width: 454px;
		height: 100%;
	}

	.form-item > input#idcard {
		width: 256px;
	}

	.birthday-wrapper {
        width: 454px;
        height: 36px;
        position: relative;
    }

    .date-picker-box {
        position: absolute;
        z-index: -1;
        left: 0;
        top:0
    }

    .birthday {
        display: inline-block;
        width: 454px;
        height: 36px;
        background-color: transparent;
        color: #fff;
		font-weight: bold;
		font-size: 16px;
		color: #231F20;
        padding-left: 14px;
        border: 1px solid #D6E7EB;
        position: relative;
        z-index: 10;
		box-sizing: border-box;
    }

    .birthday::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: url(../../../../assets/imgs/pc/children/img_ril@2x.png) no-repeat;
        width: 20px;
        height: 20px;
        border-width: thin;
        position: absolute;
        right: 0;
        top: 6px;
        z-index: 10;
    }
    
	.birthday::placeholder {
		font-size: 16px;
		font-weight: 400;
		color: #969696;
    }

	.form-item > .icon {
		position: absolute;
		left: 511px;
		width: 36px;
		height: 34px;
		border-left: 1px solid #D6E7EB;
	}

	.form-item > .icon::after {
		content: '';
		position: absolute;
		left: 6px;
		top: 6px;
		height: 22px;
		width: 22px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-image: url(../../../../assets/imgs/pc/user/img_rili@2x.png);
	}

	.form-item > .birthday-wrapper > #birthday:before {
		content: '';
		position: absolute;
		right: 0px;
		top: 0px;
		height: 36px;
		width: 34px;
		border-left: 1px solid #D6E7EB;
	}

	.form-item > input::placeholder {
		font-size: 16px;
		font-weight: 400;
		color: #969696;
	}

	.form-item > .checkbox {
		display: inline-block;
		height: 36px;
		line-height: 36px;
		position: relative;
		padding-left: 24px;
		color: #969696;
		font-size: 16px;
		font-weight: 400;
		margin-left: 16px;
		margin-right: 16px;
		cursor: pointer;
	}

	.form-item > .checkbox::after {
		content: '';
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background-image: url(../../../../assets/imgs/pc/user/img_huichange@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.form-item > .checkbox.checked::after {
		background-image: url(../../../../assets/imgs/pc/user/img_change@2x.png);
	}

	.form-item > .idcard-type {
		height: 36px;
		line-height: 36px;
		border: 1px solid #D6E7EB;
		box-sizing: border-box;
		margin-right: 16px;
		width: 182px;
		padding-left: 16px;
		cursor: pointer;
		position: relative;
		font-size: 16px;
	}

	.form-item > .idcard-type::after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 36px;
		height: 34px;
		box-sizing: border-box;
		border-left: 1px solid #D6E7EB;
	}

	.form-item > .idcard-type::before {
		content: '';
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		width: 12px;
		height: 6px;
		background-image: url(../../../../assets/imgs/pc/user/img_xiala@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.btn-save {
		width: 188px;
		height: 42px;
		border-radius: 15px;
		color: #fff;
		background-color: #36C4D0;
		line-height: 42px;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		cursor: pointer;
		margin: 40px 0px 40px 197px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	/** loading start */
    .lds-ring1 {
        display: inline-block;
        position: absolute;
        width: 32px;
        height: 32px;
		left: 20px;
    }
    
    .lds-ring1 div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 2px solid #36C4D0;
        border-radius: 50%;
        animation: lds-ring1 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #36C4D0 transparent transparent transparent;
    }
    
    .lds-ring1 div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring1 div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring1 div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring1 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    /** loading end */ 

    .lds-ring2 {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring2 div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring2 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring2 div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring2 div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring2 div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring2 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
